import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

export const LinkMap = {
  Docusaurus: "9037d02d39d1",
  "GitHub Pages": "9037d02d39d1",
  Jeklly: "9037d02d39d1",
  Gitbook: "9037d02d39d1",
  "Gatsby.js": "9037d02d39d1",
  "VuePress ": "9037d02d39d1",
  Docsify: "9037d02d39d1",
  MkDocs: "9037d02d39d1",
  SSG: "9037d02d39d1",
  JAMStack: "9037d02d39d1",
  GraphQL: "9037d02d39d1",
  REST: "9037d02d39d1",
  Markup: "9037d02d39d1",

  "Blitz JS": "e5382b9004d3",
  "Zero-API": "e5382b9004d3",
  Monolithic: "e5382b9004d3",
  "No API": "e5382b9004d3",
  SSR: "e5382b9004d3",
  CSR: "e5382b9004d3",
  Next: "e5382b9004d3",
  "State Management": "e5382b9004d3",
  Routing: "e5382b9004d3",
  Netlify: "e5382b9004d3",
  Vercel: "e5382b9004d3",

  "Ivy Compiler": "75f8187f8f16",
  "Rendering Pipeline": "75f8187f8f16",
  Angular: "75f8187f8f16",
  Deno: "75f8187f8f16",

  xState: "d6b95763631f",
  StateDesigner: "d6b95763631f",

  Bootstrap: "131c3935770d",
  "CloudFront Functions": "131c3935770d",

  "Why Deno": "4034875fefab",
  "Deno-Promise": "4034875fefab",
  "Deno-Security": "4034875fefab",
  "Deno-Build": "4034875fefab",
  "Deno-package.json": "4034875fefab",
  "Deno-node modules": "4034875fefab",
  "Deno-require": "4034875fefab",
  "Deno-index.js": "4034875fefab",

  "What is Deno": "9b58d2e94958",
  Security: "9b58d2e94958",
  ModuleSystem: "9b58d2e94958",
  TypeScript: "9b58d2e94958",
  ShipSingleExec: "9b58d2e94958",
  "Use Future Tech": "9b58d2e94958",

  Svelte: "751db72cc24a",
  "Svelte-Reactive Programming": "751db72cc24a",
  "Svelte-Compiler Centric": "751db72cc24a",

  "Netlify Platform": "36ac4b20c3c8",
  "Netlify Edge": "36ac4b20c3c8",
  "Netlify Functions": "36ac4b20c3c8",
  "Netlify Identity": "36ac4b20c3c8",
  "Netlify Forms": "36ac4b20c3c8",

  "How Netlify Works": "69a333a56762",
  "Edge Handlers": "69a333a56762",
  "Edge Computing": "69a333a56762",
  "Serverless Functions": "69a333a56762",
  "Background Functions": "69a333a56762",

  Emotion: "819c8c02f4fe",
  Faker: "819c8c02f4fe",
  Chroma: "819c8c02f4fe",
  "CSS-In-JS": "819c8c02f4fe",

  "React Dnd": "48c82d5866aa",
  "Drag And Drop": "48c82d5866aa",

  "Material UI": "11af89bae8fb",
  "sx Prop": "11af89bae8fb",

  "React-Pdf": "9d23d9830abe",
  "React-Calendar": "9d23d9830abe",
  "React-AnalogClock": "9d23d9830abe",
  "React-Date": "9d23d9830abe",
  "React-Time": "9d23d9830abe",
  "React-DateTime": "9d23d9830abe",

  jQuery: "97a680206a5",
  Sizzle: "97a680206a5",

  "React Query": "fc266da9f7fa",
  Caching: "fc266da9f7fa",
  "Optimistic Update": "fc266da9f7fa",
  "Background Fetching": "fc266da9f7fa",
  "Server State": "fc266da9f7fa",

  "React API": "a19a2a3dbf0a",
  "ReactDOM API": "a19a2a3dbf0a",
  "ReactDOMServer API": "a19a2a3dbf0a",
  "ReactTestRenderer API": "a19a2a3dbf0a",
  "ReactTestUtilities API": "a19a2a3dbf0a",
  "DOM Elements": "a19a2a3dbf0a",
  "Synthetic Events": "a19a2a3dbf0a",

  "Remix Fetch": "61129800bd47",
  "Remix Mutation": "1d2afdd015bc",
  "Remix Edge Shipping": "a59193016b2f",

  "Next 13": "1e0fbd7ef632",
  Sentry: "cc57bcd60b8e",
  LogRocket: "cc57bcd60b8e",

  "Commercial Lib": "463c6091f170",
  Bryntum: "463c6091f170",
  "AG Grid": "463c6091f170",
  "Kendo UI": "463c6091f170",
  Syncfusion: "463c6091f170",
  Highcharts: "463c6091f170",
  "React Flow Pro": "463c6091f170",
  "Dev Extreme": "463c6091f170",
}

const storiesFEFrameworks = [
  {
    title: "Refine, React Admin, Redwood, Blitz, EpicStack",
    postId: "a1a17417c707",
  },
  {
    title: "React v18.0 Updates",
    postId: "62bfa4d86cbd",
  },
  {
    title: "Svelte Nedir ve Ne Avantajı Var",
    postId: "751db72cc24a",
  },
  {
    title: "React Nedir",
    postId: "a19a2a3dbf0a",
  },
  {
    title: "Angular ve Deno",
    postId: "75f8187f8f16",
  },
  {
    title: "jQuery Nedir, Nasıl Çalışır",
    postId: "97a680206a5",
  },
]

const storiesRuntimes = [
  {
    title: "Deno Nedir, Neden Geliştirildi",
    postId: "4034875fefab",
  },
  {
    title: "Deno Nedir-2",
    postId: "9b58d2e94958",
  },
  {
    title: "Angular ve Deno",
    postId: "75f8187f8f16",
  },
]

const storiesRenderingFrameworks = [
  {
    title: "Next 13",
    postId: "1e0fbd7ef632",
  },
  {
    title: "Docusaurus ve JAMStack",
    postId: "9037d02d39d1",
  },
  {
    title: "BlitzJS ve NextJS",
    postId: "e5382b9004d3",
  },
  {
    title: "Remix Nedir-Fetch",
    postId: "61129800bd47",
  },
  {
    title: "Remix Nedir-Mutation",
    postId: "1d2afdd015bc",
  },
  {
    title: "Remix Nedir-Edge Shipping",
    postId: "a59193016b2f",
  },
]

const storiesMonitoring = [
  {
    title: "Sentry & LogRocket",
    postId: "cc57bcd60b8e",
  },
]

const storiesStateManagement = [
  {
    title: "React Query",
    postId: "fc266da9f7fa",
  },
  {
    title: "xState ve State Designer",
    postId: "d6b95763631f",
  },
]

const storiesFECloud = [
  {
    title: "Netlify Nedir-1 ?",
    postId: "36ac4b20c3c8",
  },
  {
    title: "Netlify Nasıl Çalışır-2 ",
    postId: "69a333a56762",
  },
  {
    title: "Bootstrap, CloudFront Functions",
    postId: "131c3935770d",
  },
]

const storiesUIComponents = [
  {
    title: "React PDF, Calendar, Clock",
    postId: "9d23d9830abe",
  },
  {
    title: "Material UI 5.0.0",
    postId: "11af89bae8fb",
  },
  {
    title: "Commercial UI Components",
    postId: "463c6091f170",
  },
]

const storiesUtilities = [
  {
    title: "React Dnd",
    postId: "48c82d5866aa",
  },
  {
    title: "React Lazy Log",
    postId: "593e9e6a9a7b",
  },
  {
    title: "Emotion, Faker.js",
    postId: "819c8c02f4fe",
  },
]

const storiesGroup = [
  {
    title: "Frontend Frameworks",
    storyPart: storiesFEFrameworks,
  },
  {
    title: "Rendering Frameworks",
    storyPart: storiesRenderingFrameworks,
  },
  {
    title: "JS Runtimes",
    storyPart: storiesRuntimes,
  },
  {
    title: "Monitoring & RUM",
    storyPart: storiesMonitoring,
  },
  {
    title: "State Management",
    storyPart: storiesStateManagement,
  },
  {
    title: "FE Cloud",
    storyPart: storiesFECloud,
  },
  {
    title: "UI Components",
    storyPart: storiesUIComponents,
  },
  {
    title: "Utilities",
    storyPart: storiesUtilities,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Libs",
  path: "urun-ve-kutuphaneler",
}

const LibsAndProductsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default LibsAndProductsPage
